<template>
  <div class="grid30">
    <div class="div1">
      <h4>заказчик</h4>
      <router-link :to="'/counterparty/'+tender.customer.id" v-if="isInSup">{{
          tender.customer.fullName
        }}
      </router-link>
      <span v-else>{{ tender.customer.fullName }}</span>
      <h4>тема</h4>
      <p>{{ tender.theme }}</p>
    </div>

    <div class="div2">
      <h2>Общая информация</h2>

      <div>
        <h4>начальная цена</h4>
        <h3>{{ tender.maxPrice | formatPrice }}</h3>
      </div>

      <div>
        <h4>закон</h4>
        <p>{{ getLawByProcedure(tender.procedure) }}</p>
      </div>

      <div class="width50">

        <div>
          <h4>уровень</h4>
          <p>{{ customerLevelsDict[tender.customerLevel] || '—' }}</p>
        </div>


        <div>
          <h4>субъект рф</h4>
          <p>{{ tender.customer.region ? tender.customer.region.regionName : '—' }}</p>
        </div>

      </div>

      <div>
        <h4>процедура закупки</h4>
        <p>{{ purchaseProceduresDict[tender.procedure] }}</p>
      </div>

      <div>
        <h4>направление деятельности</h4>
        <p>Не указано</p>
      </div>

      <a v-if="tender.url" :href="tender.url" class="btn outline" target="_blank">Тендер на портале
        zakupki.gov.ru</a>

    </div>
    <div class="div3">
      <h2>Обеспечение</h2>

      <h4>заявка</h4>
      <div>
        <div><span class="rub"></span><span v-if="tender.appGuarantee">
              {{ tender.appGuarantee | formatPrice }}
            </span><span v-else>—</span></div>
        <div><span class="percent"></span><span v-if="tender.appGuaranteePart">
              {{ tender.appGuaranteePart }}
            </span><span v-else>—</span>
        </div>
      </div>

      <h4>Исполнение</h4>
      <div>
        <div><span class="rub"></span><span v-if="tender.contrGuarantee">
              {{ tender.contrGuarantee | formatPrice }}
            </span><span v-else>—</span></div>
        <div><span class="percent"></span><span v-if="tender.contrGuaranteePart">
              {{ tender.contrGuaranteePart }}
            </span><span v-else>—</span></div>
      </div>

      <h4>гарантия</h4>
      <div>
        <div><span class="rub"></span><span v-if="tender.provisionWarranty">
              {{ tender.provisionWarranty | formatPrice }}
            </span><span v-else>—</span></div>
        <div><span class="percent"></span><span v-if="tender.provisionWarrantyPart">
              {{ tender.provisionWarrantyPart }}
            </span><span v-else>—</span></div>
      </div>


    </div>
    <div class="div4">
      <h2>Результат</h2>

      <h4>Победитель</h4>
      <p>—</p>

      <h4>сумма контракта</h4>
      <p>—</p>
    </div>

    <div class="div5" v-if="isInSup">
      <h2>Заявки</h2>


      <div class="application-entry application-entry-incard" v-for="(app, appIndex) in tender.applications" :key="appIndex+'iasnasdf'">

<div class="request-header">
          <h5>
            <span class="request-status risc" v-if="app.riskAssessment==='HIGH'">Высокорисковая заявка</span>
            <span class="request-status priority" v-if="app.highPriority">Высокий приоритет</span>
          </h5>
</div>

        <div class="request-wrap">
          <div class="request-info">
            <div>
              <h6>юр. лицо</h6>
              <p>{{ app.ourOrg ? app.ourOrg.shortName : app.differentOrgName }}</p>
            </div>
            <div>
              <h6>ответственные</h6>
              <p>
                <router-link :to="'/employee/'+app.offerExpert.id+'/info'" v-if="app.offerExpert">
                  {{ getExpertShortName(app.offerExpert) }}
                </router-link>
                <br/>
                <router-link :to="'/employee/'+app.formalExpert.id+'/info'" v-if="app.formalExpert">
                  {{ getExpertShortName(app.formalExpert) }}
                </router-link>
              </p>
            </div>
            <div>
              <h6>обеспечение</h6>
              <p>{{ app.guaranteePaid ? guaranteeTypeDict[app.guaranteeType] : 'Не внесено' }}</p>
            </div>
            <div>
              <h6>статус</h6>
              <p>{{ applicationStatusDict[app.applicationStatus] }}</p>
            </div>
            <div>
              <h6>Сумма</h6>
              <p>{{ app.appPrice | formatPrice }} (-{{ discount(app) }} %)</p>
            </div>
            <div>
              <v-btn outlined @click="editAppIndex=appIndex;showAddAppDialog=true">Редактировать
              </v-btn>
              <v-btn outlined @click="deleteApplication(app.id)" v-if="userInOneOfRoles('ROLE_OFFER', 'ROLE_PARTNER', 'ROLE_ADMIN')">Удалить
              </v-btn>
            </div>
          </div>
        </div>
      </div>

      <v-btn outlined @click="editAppIndex=null;showAddAppDialog=true" v-if="userInOneOfRoles('ROLE_OFFER', 'ROLE_PARTNER', 'ROLE_ADMIN')">Добавить заявку
      </v-btn>

      <v-dialog
          persistent
          v-model="showAddAppDialog"
          width="500"
      >
        <add-offer-application-dialog v-if="userInOneOfRoles('ROLE_OFFER', 'ROLE_PARTNER', 'ROLE_ADMIN') && showAddAppDialog" :edit-app-index="editAppIndex" :tender="tender"
                                      @close="showAddAppDialog=false"
                                      @update="reloadData()"></add-offer-application-dialog>
        <add-formal-application-dialog v-if="userInOneOfRoles('ROLE_FORMAL') && showAddAppDialog" :edit-app-index="editAppIndex" :tender="tender"
                                      @close="showAddAppDialog=false"
                                      @update="reloadData()"></add-formal-application-dialog>
      </v-dialog>

    </div>
  </div>
</template>

<script>
import AddOfferApplicationDialog from "@/components/dialogs/AddOfferApplicationDialog";
import AddFormalApplicationDialog from "@/components/dialogs/AddFormalApplicationDialog";
import {dictToObject, getExpertShortName, userInOneOfRoles} from "@/modules/CommonUtils";
import {
  applicationStatusDict,
  customerLevelsDict,
  customerTimeZoneDict, guaranteeTypeDict,
  purchaseLawDict,
  purchaseProceduresDict
} from "@/modules/NSI";

export default {
  name: "TenderInfoTab",
  props: ['tender', 'deleteApplicationFunc', 'reloadDataFunc'],
  components: {AddOfferApplicationDialog, AddFormalApplicationDialog},
  data() {
    return {
      editAppIndex: null,
      isInSup: true,
      apiError: false,
      apiURL: null,
      showAddAppDialog: false,
      purchaseProceduresDict: dictToObject(purchaseProceduresDict),
      customerLevelsDict: dictToObject(customerLevelsDict),
      applicationStatusDict: dictToObject(applicationStatusDict),
      guaranteeTypeDict: dictToObject(guaranteeTypeDict),
      customerTimeZoneDict: customerTimeZoneDict
    }
  },
  methods: {
    userInOneOfRoles,
    getExpertShortName,
    discount(app) {
      let price = app.appPrice
      return (100 - price / this.tender.maxPrice * 100).toFixed(0)
    },
    async reloadData() {
      await this.reloadDataFunc();
      this.$forceUpdate()
    },

    async deleteApplication(appId) {
      await this.deleteApplicationFunc(appId)
      this.$forceUpdate()
      console.log('updated')
    },

    getLawByProcedure(procedure) {
      const lawCode = purchaseProceduresDict.find(e => e.code === procedure).law
      return purchaseLawDict.find(e => e.code === lawCode).title
    }
  },
  beforeMount() {
  }
}
</script>

<style scoped>

</style>